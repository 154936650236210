<template>
	<v-container class="main-container">
		<backTo elClass="mb-10 d-block" :link="`/admin/quiz-editor/${quizId}`"
			><template #text>Back</template></backTo
		>
		<admin-default-header class="mb-4">
			{{ originName }}
		</admin-default-header>
		<admin-default-subheader class="mb-4">
			Quiz Settings
		</admin-default-subheader>

		<v-form ref="form">
			<InputTextField
				:id="'new_quiz_name'"
				v-model="quizName"
				:rules="[rules.required]"
			>
				<template #label>Quiz Name</template>
			</InputTextField>
			<InputTextArea v-model="description" :id="'new_quiz_description'">
				<template #label> Description (Optional)</template>
			</InputTextArea>
		</v-form>

		<v-btn
			class="admin-primary-button primary-contrast-background"
			:loading="isLoading"
			@click="save"
			>Done</v-btn
		>
	</v-container>
</template>

<script>
import AdminDefaultSubheader from "@/components/AdminDefaultSubheader.vue";
import AdminDefaultHeader from "@/components/AdminDefaultHeader.vue";
import BackTo from "@/components/BackTo.vue";
import InputTextField from "@/components/InputTextField.vue";
import { put } from "@/util/requests/put";
import { mapGetters } from "vuex";

export default {
	name: "QuizSettings",
	components: {
		AdminDefaultSubheader,
		AdminDefaultHeader,
		BackTo,
		InputTextField
	},
	computed: {
		...mapGetters(["allQuizzes", "isLoading"]),
		quiz() {
			return this.allQuizzes.find(el => el.id === this.quizId);
		},
		quizId() {
			return Number(this.$route.params.id);
		},
		originName() {
			return this.quiz.name;
		}
	},
	data() {
		return {
			quizName: "",
			description: "",
			rules: {
				required: value => !!value || "Required."
			}
		};
	},
	methods: {
		async save() {
			try {
				await this.$store.dispatch("setIsLoading", true);
				await put("/quiz", this.quizId, {
					name: this.quizName,
					organization_id: this.quiz.organization_id,
					start_button_text: this.quiz.start_button_text,
					started_heading: this.quiz.started_heading,
					started_html: this.quiz.started_html,
					completed_heading: this.quiz.completed_heading,
					completed_html: this.quiz.completed_html,
					sort: this.quiz.sort,
					custom: this.quiz.custom,
					custom_course_id: this.quiz.custom_course_id,
					category_id: this.quiz.category_id,
					type: this.quiz.type
				});
				await this.$store.dispatch("getQuizzes");
			} catch (error) {
				console.error(error);
			}
			await this.$store.dispatch("setIsLoading", false);
		}
	}
};
</script>

<style></style>
